import "./styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
const Privacy = () => {
  return (
    <div className="content">
      <div className="inner">
        <Link type="button" to={"/"}>
          <ArrowBackIosIcon
            style={{
              fontSize: "40px",
              color: "black ",
              marginLeft: "30px",
              paddingTop: "30px",
            }}
          />
        </Link>
        <h1 className="title">Privacy Policy</h1>
        <p className="paragraph">
          This privacy policy is to let you know how Pocket Mosque handles data.
          This notice provides you with information regarding your rights and
          explains how, why and when we collect and process your data. If you
          have any questions or want to exercise any of your rights set out in
          this privacy notice, please contact us at Contact@PocketMosque.com.
        </p>
        <h2 className="subtitle">What this policy covers</h2>
        <p className="paragraph">
          This privacy notice only covers the current update of Pocket
          Mosque(v1.0). Future versions of the Pocket Mosque may involve changes
          to data handling policy. This will require you to review and agree to
          a new privacy policy covering the new changes and/or features.
        </p>
        <h2 className="subtitle">Your Privacy is important to us</h2>
        <p className="paragraph">
          It should be a given that your privacy is safe when using Pocket
          Mosque and we made sure our App has no trails of user data for any
          reason. The only thing we require from you is your geolocation which
          is used for accurate prayer times and right direction for the Qibla.
          Your Geolocation is erased everytime you go off the app which
          guarantees your safety whenever using Pocket Mosque. No other data is
          collected (including analytics or personal information*).
        </p>
        <h2 className="subtitle">What are Your rights </h2>
        <p className="paragraph">
          Under data protection regulation, you have...
          <ul>
            <li>
              the right to be informed about the collection and use of your
              personal data
            </li>{" "}
            <li>
              the right to access the personal data we hold about you via a
              Subject Access Request
            </li>{" "}
            <li>the right to correct inaccurate personal data</li>{" "}
            <li>the right to have your personal data deleted</li>
            <li>
              {" "}
              the right to request restriction of or suppression of your
              personal data
            </li>{" "}
            <li>
              the right to make use of your personal data for your own purposes
              across different services
            </li>{" "}
            <li>
              the right to object to the processing of your personal data in
              some circumstances
            </li>
          </ul>
        </p>
        <h2 className="subtitle">Making a rights request</h2>
        <p className="paragraph">
          If you would like to exercise any of the rights outlined above, please
          contact us by email at contact@pocketmosque.co.uk{" "}
        </p>{" "}
        <h2 className="subtitle">Making a complaint</h2>{" "}
        <p className="paragraph">
          {" "}
          You have the right to complain to us and to the data protection
          regulator, the Information Commissioner’s Office. Their address is
          Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, United
          Kingdom. They can be contacted by phone on +44303 123 1113 (local
          rate) or +441625 545745 (national rate). You can find details on how
          to report a concern at ico.org.uk/make-a-complaint/
        </p>
        <h2 className="subtitle">Changes in the Privacy Policy</h2>
        <p className="paragraph">
          Our privacy policy may have little changes from time to time. This can
          be because of things such as adding new features and to cover legal
          grounds regarding the feature. The privacy policy will always be
          updated here so our users can see the changes in privacy policy
        </p>
        <h2 className="subtitle"> Contact us</h2>
        <p className="paragraph">
          For more information about our Privacy Notice, or if you would like to
          make a complaint, please contact us at contact@pocketmosque.co.ukk
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default Privacy;
