import background from "../../../assets/pocketmosque.png";
import "./styles.css";
import Lottie from "lottie-react";
import manOnLaptop from "../../../assets/manOnLaptop.json";

export function About() {
  return (
    <div id="About">
      <div style={{ width: "450px", marginTop: "20px" }}>
        <h2 className="topText">
          We’ve worked hard to create an ad free app for our Muslim brothers and
          sisters!
        </h2>
        <h2 className="bottomText">
          With life being so hectic, we wanted to design something simple for
          you!
          <br />
          <br />
          With the help of pocket mosque, knowing prayer times is easier than
          ever! With the added benefit of having 100% privacy.
          <br />
          <br />
          Once the app is downloaded, it only takes one tap to know prayer
          times!
          <br />
          <br />
          Ensuring you’re always on time for Salah.
        </h2>
        <br />
      </div>
      <Lottie animationData={manOnLaptop} loop={true} className="lottie" />
    </div>
  );
}
