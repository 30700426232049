import Homescreen from "../../../assets/homescreen.png";
import Qiblascreen from "../../../assets/qiblascreen.png";
import Duasscreen from "../../../assets/duascreen.png";
import "./styles.css";

export default function Features() {
  return (
    <div id="Features" className="features">
      <div className="screenshot_section_features">
        <img alt="Home Screen" className="screenshots" src={Homescreen}></img>
        <div className="text_section">
          <h4 className="title_features">Prayer Times </h4>
          <p className="paragraph_features">
            The 5 prayer times all presented to you at a glance with accurate
            prayer times wherever your location.
            <br />
            <br />
            Dynamic card switching, displaying the upcoming prayer time so you
            will always be up to date with prayer times.
            <br />
            <br />
            Prayer times can be used worldwide thanks to multiple prayer
            calculation methods found in the settings page.
          </p>
        </div>
      </div>
      <div className="qibla_section_features" style={{ marginTop: "100px" }}>
        <div className="text_section">
          <h4 className="title_features">Qibla Compass</h4>
          <p className="paragraph_features">
            Qibla Compass allowing you to pray towards the right direction
            whether your at work or just somewhere new and need to find the
            direction of Makkah.
          </p>
        </div>
        <img alt="Qibla Screen" className="screenshots" src={Qiblascreen}></img>
      </div>
      <div
        className="screenshot_section_features"
        style={{ marginTop: "150px" }}
      >
        <img alt="Dua Screen" className="screenshots" src={Duasscreen}></img>
        <div className="text_section">
          <h4 className="title_features">Daily Duas</h4>
          <p className="paragraph_features">
            Daily Duas which are used in everyday life, refreshed every time you
            go on the app so there’s always a new dua to learn!
            <br />
            <br />
            Plans to bring more duas and a category section to the app soon In
            Sha Allah!
          </p>
        </div>
      </div>
    </div>
  );
}
