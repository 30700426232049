import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Privacy from "./screens/Privacy";
function App() {
  return (

      <Router>
        <Routes>
          <Route path="/" caseSensitive={false} element={<Home />} />
          <Route
              path="/Privacy"
              caseSensitive={false}
              element={<Privacy />}
          />
        </Routes>
      </Router>

  
  )
}

export default App;
