import "./styles.css";
import Logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <div className="Footer">
      <footer className="footer-distributed">
        <div className="footer-left">
          <img
            alt="Logo"
            src={Logo}
            style={{ height: "85.56px", width: "85.56", alignSelf: "center" }}
          />
          <h3>Pocket Mosque</h3>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>Our Social Media's</span>
            Any problems with the app? Let us know. Like the app? Let others
            know
          </p>

          <div className="footer-icons">
            <a href="https://www.instagram.com/pocketmosque" target="blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://twitter.com/pocketmosque" target="blank">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://discord.gg/4pB3StHpP7" target="blank">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
