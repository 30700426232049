import background from "../../../assets/pocketmosque.png";
import "./styles.css";

import rectangle from "../../../assets/rectangle.svg";
import { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import NavbarComponent from "../../../components/Navbar";

export function Landing() {
  const [index, setIndex] = useState(0);
  const TEXTS = ["Your Friendly", "‎ ‎ ‎ ‎ ‎ ‎ Pocket"];
  const TEXTS2 = ["Prayer App", "‎ ‎ ‎ ‎ ‎ ‎Mosque"];

  useEffect(() => {
    const intervalId = setTimeout(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="main " style={{ backgroundImage: `url(${background})` }}>
      <NavbarComponent />
      <div className="section">
        <div className="head-text">
          <div className="head-image">
            <img
              src={rectangle}
              style={{ marginBottom: 10 }}
              className="rectangle"
              alt="rectangle"
            />
          </div>
          <div className="center__text">
            <h1
              className="title-top"
              style={{ width: 400, textAlign: "center" }}
            >
              <TextTransition springConfig={presets.wobbly}>
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
            </h1>
          </div>
        </div>
        <div className="head-text">
          <div className="head-image">
            <img src={rectangle} className="rectangle" alt="rectangle" />
          </div>
          <div className="center__text">
            <h1
              className="title-bottom"
              style={{ width: 400, textAlign: "center" }}
            >
              <TextTransition springConfig={presets.wobbly}>
                {TEXTS2[index % TEXTS2.length]}
              </TextTransition>
            </h1>
          </div>
        </div>

        <br />

        <h3 className="sub-text">5 &#9733; on the App Store</h3>
        <a
          href="https://apps.apple.com/us/app/pocket-mosque/id1498791262"
          target="blank"
        >
          <button on className="btn--landing">
            Download now
          </button>
        </a>
      </div>

      <div class="custom-shape-divider-bottom-1663423035">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
}
