import Privacy from "../Privacy";
import { Landing } from "./Landing";
import { About } from "./About";
import Features from "./Features";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop/index";
import Navbar from "../../components/Navbar";
export default function Home() {
  return (
    <div style={{ backgroundColor: "white", overflowX: "hidden" }}>
      <Landing />
      <About />
      <Features />
      <Footer />
      <ScrollToTop />
    </div>
  );
}
