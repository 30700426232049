import { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.css";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/logo.png";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";

const NavbarComponent = () => {
  const [showNavList, setShowNavList] = useState(false);

  const toggleNavList = () => setShowNavList(!showNavList);

  return (
    <Navbar className={"center nav"}>
      <Container style={{ display: "flex" }}>
        <img
          alt="Logo"
          src={Logo}
          style={{
            height: "85.56px",
            width: "85.56",
            marginTop: "10px",
            marginLeft: "10px",
          }}
        />
        <Nav
          className="nav__list"
          style={{ display: showNavList ? "flex" : null }}
        >
          <div className="nav__list-item">
            {" "}
            <Nav.Link
              className="nav__list-item link link--nav"
              href="#About"
              onClick={toggleNavList}
            >
              About
            </Nav.Link>
          </div>
          <div className="nav__list-item">
            {" "}
            <Nav.Link
              className="nav__list-item link link--nav"
              href="#Features"
              onClick={toggleNavList}
            >
              Features
            </Nav.Link>
          </div>

          <div className="nav__list-item">
            <LinkContainer to="/Privacy">
              <Nav.Link className="nav__list-item link link--nav">
                Privacy
              </Nav.Link>
            </LinkContainer>
          </div>
        </Nav>
      </Container>
      <button
        type="button"
        onClick={toggleNavList}
        style={{ right: 20, position: "absolute" }}
        className=" nav__hamburger"
        aria-label="toggle navigation"
      >
        {showNavList ? (
          <CloseIcon
            style={{ color: "black", height: "50px", width: "40px" }}
          />
        ) : (
          <MenuIcon style={{ color: "white", height: "50px", width: "40px" }} />
        )}
      </button>
    </Navbar>
  );
};

export default NavbarComponent;
